import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, navigate } from 'gatsby'
import { IoIosArrowBack } from 'react-icons/io'

import { SEO } from '@components'

import '@styles/contact.scss'

const Contact = () => (
  <>
    <SEO title={'Contact'} />
    <section className={'contact-container'}>
    <div className={'form-container'}>
      <IoIosArrowBack className={'form-container--back'} onClick={() => navigate(-1)} />
      <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/6qmIRzNzeADYiiJdkbEXVFye80ZjPMPamL7ZxeoAnd9T2WPGEDKGCD7tQ923MgGjnB">
        <Helmet>
          <script src="https://webforms.pipedrive.com/f/loader" async></script>
        </Helmet>
      </div>
    </div>
    </section>
  </>
) 

export const query = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Contact
